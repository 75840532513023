import { Title } from '@solidjs/meta';
import { ActivityIndicator } from '@troon/ui';
import { clientOnly } from '@solidjs/start';

const LogoutAction = clientOnly(async () => ({ default: (await import('./components/logout-action')).LogoutAction }));

export default function Logout() {
	return (
		<div class="flex items-center justify-center p-8">
			<Title>Log out | Troon</Title>
			<ActivityIndicator>Logging out…</ActivityIndicator>
			<LogoutAction />
		</div>
	);
}
